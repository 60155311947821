import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-center"
}
const _hoisted_2 = { class: "text-lg font-semibold" }

import { useRoute } from 'vue-router';

import { POOL_MIGRATIONS } from '@/components/forms/pool_actions/MigrateForm/constants';
import MigrateForm from '@/components/forms/pool_actions/MigrateForm/MigrateForm.vue';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const route = useRoute();

/**
 * STATE
 */
const fromPoolId = route.params.from as string;
const toPoolId = route.params.to as string;
const poolMigrationInfo = POOL_MIGRATIONS.find(
  poolMigrationInfo =>
    poolMigrationInfo.fromPoolId === fromPoolId &&
    poolMigrationInfo.toPoolId === toPoolId
);

return (_ctx: any,_cache: any) => {
  return (_unref(poolMigrationInfo))
    ? (_openBlock(), _createBlock(MigrateForm, {
        key: 0,
        poolMigrationInfo: _unref(poolMigrationInfo)
      }, null, 8, ["poolMigrationInfo"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('migratePool.errorLoadingMigration.title')), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('migratePool.errorLoadingMigration.description')), 1)
      ]))
}
}

})