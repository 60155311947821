import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full border-b p-4 dark:border-gray-900" }
const _hoisted_2 = { class: "-mt-2 p-4" }
const _hoisted_3 = { class: "text-secondary" }

import { PoolMigrationInfo } from '@/components/forms/pool_actions/MigrateForm/types';

type Props = {
  poolMigrationInfo: PoolMigrationInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    noPad: "",
    shadow: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t(`migratePool.${__props.poolMigrationInfo.type}.whyMigrate.title`)), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`migratePool.${__props.poolMigrationInfo.type}.whyMigrate.description`)), 1)
      ])
    ]),
    _: 1
  }))
}
}

})