import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4 flex items-center justify-between text-sm text-gray-400 dark:text-gray-600" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2" }

import { StablePoolEncoder, WeightedPoolEncoder } from '@balancer-labs/sdk';
import {
  TransactionReceipt,
  TransactionResponse,
} from '@ethersproject/abstract-provider';
import { BigNumber, BigNumberish } from 'ethers';
import { computed, onBeforeMount, reactive, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import useRelayerApproval, {
  Relayer,
} from '@/composables/trade/useRelayerApproval';
import useConfig from '@/composables/useConfig';
import useEthers from '@/composables/useEthers';
import { isStableLike } from '@/composables/usePool';
import { dateTimeLabelFor } from '@/composables/useTime';
import useTransactions from '@/composables/useTransactions';
import useUserSettings from '@/composables/useUserSettings';
// Libs
import { balancer } from '@/lib/balancer.sdk';
// Services
import { balancerContractsService } from '@/services/balancer/contracts/balancer-contracts.service';
// Types
import { Pool } from '@/services/pool/types';
// Composables
import useWeb3 from '@/services/web3/useWeb3';
import { TokenInfo } from '@/types/TokenList';
import { TransactionActionInfo } from '@/types/transactions';

import { MigrateMathResponse } from '../../../composables/useMigrateMath';

/**
 * TYPES
 */
type Props = {
  fromPool: Pool;
  toPool: Pool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
  math: MigrateMathResponse;
  disabled?: boolean;
};

type MigratePoolState = {
  init: boolean;
  confirming: boolean;
  confirmed: boolean;
  confirmedAt: string;
  receipt?: TransactionReceipt;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    fromPool: { type: null, required: true },
    toPool: { type: null, required: true },
    fromPoolTokenInfo: { type: null, required: true },
    toPoolTokenInfo: { type: null, required: true },
    math: { type: null, required: true },
    disabled: { type: Boolean, required: false }
  } as unknown as undefined,
  emits: ["success"] as unknown as undefined,
  setup(__props: {
  fromPool: Pool;
  toPool: Pool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
  math: MigrateMathResponse;
  disabled?: boolean;
}, { emit }: { emit: ({
  (e: 'success', value: TransactionReceipt): void;
}), expose: any, slots: any, attrs: any }) {

const props = __props


const {
  fiatTotalLabel,
  bptBalanceScaled,
  fullAmountsScaled,
  tokenCount,
  shouldFetchBatchSwap,
} = toRefs(props.math);



/**
 * STATE
 */
const migratePoolState = reactive<MigratePoolState>({
  init: false,
  confirming: false,
  confirmed: false,
  confirmedAt: '',
});

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const { networkConfig } = useConfig();
const { getProvider, explorerLinks, account, blockNumber } = useWeb3();
const { addTransaction } = useTransactions();
const { txListener, getTxConfirmedAt } = useEthers();
const { slippageScaled } = useUserSettings();
const batchRelayerApproval = useRelayerApproval(Relayer.BATCH);

const migrateAction: TransactionActionInfo = {
  label: t('migratePool.previewModal.actions.title'),
  loadingLabel: t('migratePool.previewModal.actions.loading'),
  confirmingLabel: t('confirming'),
  action: submit,
  stepTooltip: t('migratePool.previewModal.actions.migrationStep'),
};

const actions = ref<TransactionActionInfo[]>([migrateAction]);

/**
 * COMPUTED
 */
const explorerLink = computed(() =>
  migratePoolState.receipt
    ? explorerLinks.txLink(migratePoolState.receipt.transactionHash)
    : ''
);

const transactionInProgress = computed(
  () =>
    migratePoolState.init ||
    migratePoolState.confirming ||
    migratePoolState.confirmed
);

/**
 * METHODS
 */
async function handleTransaction(tx): Promise<void> {
  addTransaction({
    id: tx.hash,
    type: 'tx',
    action: 'migratePool',
    summary: t('transactionSummary.migratePool', [
      fiatTotalLabel.value,
      props.fromPoolTokenInfo.symbol,
      props.toPoolTokenInfo.symbol,
    ]),
    details: {
      fromPool: props.fromPool,
      toPool: props.toPool,
      totalFiatPoolInvestment: fiatTotalLabel.value,
    },
  });

  migratePoolState.confirmed = await txListener(tx, {
    onTxConfirmed: async (receipt: TransactionReceipt) => {
      emit('success', receipt);
      migratePoolState.confirming = false;
      migratePoolState.receipt = receipt;

      const confirmedAt = await getTxConfirmedAt(receipt);
      migratePoolState.confirmedAt = dateTimeLabelFor(confirmedAt);
    },
    onTxFailed: () => {
      migratePoolState.confirming = false;
    },
  });
}

async function submit() {
  try {
    let tx: TransactionResponse;
    migratePoolState.init = true;

    let userData = '';
    if (isStableLike(props.fromPool.poolType)) {
      userData = StablePoolEncoder.exitExactBPTInForTokensOut(
        bptBalanceScaled.value
      );
    } else {
      userData = WeightedPoolEncoder.exitExactBPTInForTokensOut(
        bptBalanceScaled.value
      );
    }

    const txInfo = await balancer.relayer.exitPoolAndBatchSwap({
      exiter: account.value,
      swapRecipient: account.value,
      poolId: props.fromPool.id,
      exitTokens: props.fromPool.tokensList.map(t => t.toLowerCase()),
      userData,
      expectedAmountsOut: fullAmountsScaled.value.map(amount =>
        amount.toString()
      ),
      finalTokensOut: new Array(tokenCount.value).fill(props.toPool.address),
      slippage: slippageScaled.value,
      fetchPools: {
        fetchPools: true,
        fetchOnChain: false,
      },
    });

    const hasInvalidAmount = (txInfo.outputs?.amountsOut || []).some(
      (amount: BigNumberish) => BigNumber.from(amount).isZero()
    );

    if (hasInvalidAmount) {
      throw new Error('exitPoolAndBatchSwap returned invalid amounts.');
    }

    tx = await balancerContractsService.batchRelayer.execute(
      txInfo,
      getProvider()
    );

    migratePoolState.init = false;
    migratePoolState.confirming = true;

    console.log('Receipt', tx);

    handleTransaction(tx);
    return tx;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  if (!batchRelayerApproval.isUnlocked.value) {
    // Prepend relayer approval action if batch relayer not approved
    actions.value.unshift(batchRelayerApproval.action.value);
  }
});

/**
 * WATCHERS
 */
watch(blockNumber, async () => {
  if (shouldFetchBatchSwap.value && !transactionInProgress.value) {
    await props.math.getBatchSwap();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_BalActionSteps = _resolveComponent("BalActionSteps")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_unref(migratePoolState).confirmed)
      ? (_openBlock(), _createBlock(_component_BalActionSteps, {
          key: 0,
          actions: actions.value,
          disabled: __props.disabled
        }, null, 8, ["actions", "disabled"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BalIcon, { name: "clock" }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(migratePoolState).confirmedAt), 1)
            ]),
            _createVNode(_component_BalLink, {
              href: _unref(explorerLink),
              external: "",
              noStyle: "",
              class: "group flex items-center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm",
                  class: "ml-px transition-colors group-hover:text-pink-500"
                })
              ]),
              _: 1
            }, 8, ["href"])
          ]),
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'pool', params: { id: __props.toPool.id } },
            color: "gray",
            outline: "",
            block: "",
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('goToMigratedPool')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 64))
  ]))
}
}

})