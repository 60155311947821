import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg border p-3 dark:border-gray-800 dark:bg-gray-800" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-secondary" }
const _hoisted_4 = { class: "ml-2 inline-flex items-center rounded-lg border bg-gray-50 py-1 px-2 dark:border-gray-800 dark:bg-gray-700" }

import { ref } from 'vue';

import useTokens from '@/composables/useTokens';
import { Pool } from '@/services/pool/types';
import { TokenInfo } from '@/types/TokenList';

type Props = {
  pool: Pool;
  poolTokenInfo: TokenInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    poolTokenInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  poolTokenInfo: TokenInfo;
}) {



/**
 * STATE
 */
const isExpanded = ref(false);

/**
 * COMPOSABLES
 */
const { getToken } = useTokens();

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BalBreakdown, {
      items: __props.pool.tokensList,
      class: "w-full cursor-pointer select-none",
      offsetClassOverrides: "mt-4 ml-3",
      initVertBarClassOverrides: "h-6 -mt-6",
      size: "lg",
      hideItems: !isExpanded.value,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isExpanded.value = !isExpanded.value))
    }, {
      item: _withCtx(({ item: address }) => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BalAsset, {
            address: address,
            class: "mr-2"
          }, null, 8, ["address"]),
          _createTextVNode(" " + _toDisplayString(_unref(getToken)(address).symbol), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BalAsset, {
            address: __props.pool.address,
            class: "mr-2",
            size: 36
          }, null, 8, ["address"]),
          _createElementVNode("div", null, [
            _createElementVNode("div", null, _toDisplayString(__props.poolTokenInfo.symbol), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(__props.poolTokenInfo.name), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["items", "hideItems"])
  ]))
}
}

})