
import { computed, defineComponent, PropType, Ref, toRefs } from 'vue';
import { useStore } from 'vuex';

import AppSlippageForm from '@/components/forms/AppSlippageForm.vue';
import useEthereumTxType from '@/composables/useEthereumTxType';
import useFathom from '@/composables/useFathom';
import useNumbers from '@/composables/useNumbers';
import { ethereumTxTypeOptions } from '@/constants/options';
import useWeb3 from '@/services/web3/useWeb3';
import IconSettings from '@/components/icons/SettingsIcon.vue';

export enum TradeSettingsContext {
  trade,
  invest,
}

export default defineComponent({
  name: 'TradeSettingsPopover',

  components: {
    AppSlippageForm,
    IconSettings,
  },

  props: {
    context: {
      type: [String, Number] as PropType<TradeSettingsContext>,
      required: true,
    },
    isGassless: { type: Boolean, default: false },
  },
  data() {
    return {
      settings: require('@/components/icons/SettingsIcon.svg'),
      x: require('@/components/icons/x.svg'),
      open: false,
    };
  },
  computed: {
    Picture() {
      if (this.open == false) {
        return this.settings;
      } else {
        return this.x;
      }
    },
  },
  setup(props) {
    // DATA
    const { context }: { context: Ref<TradeSettingsContext> } = toRefs(props);

    // COMPOSABLES
    const store = useStore();
    const { fNum } = useNumbers();
    const { explorerLinks, isEIP1559SupportedNetwork } = useWeb3();
    const { trackGoal, Goals } = useFathom();
    const { ethereumTxType, setEthereumTxType } = useEthereumTxType();

    // COMPUTED
    const appTransactionDeadline = computed<number>(
      () => store.state.app.transactionDeadline
    );

    // METHODS
    const setTransactionDeadline = transactionDeadline =>
      store.commit('app/setTransactionDeadline', transactionDeadline);

    function onActivatorClick(): void {
      if (context.value === TradeSettingsContext.trade) {
        trackGoal(Goals.ClickTradeSettings);
      } else if (context.value === TradeSettingsContext.invest) {
        trackGoal(Goals.ClickInvestSettings);
      }
    }

    return {
      Goals,
      // types,
      TradeSettingsContext,
      // computed
      appTransactionDeadline,
      isEIP1559SupportedNetwork,
      // methods
      setTransactionDeadline,
      fNum,
      explorer: explorerLinks,
      onActivatorClick,
      ethereumTxType,
      setEthereumTxType,
      ethereumTxTypeOptions,
    };
  },
});
