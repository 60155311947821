import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d31fb39c")
const _hoisted_1 = { class: "relative mt-4 divide-y rounded-lg border dark:divide-gray-700 dark:border-gray-700" }
const _hoisted_2 = { class: "flex items-center p-3" }
const _hoisted_3 = { class: "arrow-container" }
const _hoisted_4 = { class: "flex items-center border-t p-3 dark:border-gray-900" }
_popScopeId()

import { TokenInfo } from '@/types/TokenList';

/**
 * TYPES
 */
type Props = {
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    fromPoolTokenInfo: { type: null, required: true },
    toPoolTokenInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BalAsset, {
        address: __props.fromPoolTokenInfo.address,
        class: "mr-2",
        size: 36
      }, null, 8, ["address"]),
      _createElementVNode("div", null, _toDisplayString(__props.fromPoolTokenInfo.symbol), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ArrowDownIcon)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BalAsset, {
        address: __props.toPoolTokenInfo.address,
        class: "mr-2",
        size: 36
      }, null, 8, ["address"]),
      _createElementVNode("div", null, _toDisplayString(__props.toPoolTokenInfo.symbol), 1)
    ])
  ]))
}
}

})