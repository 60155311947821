import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ml-1" }
const _hoisted_2 = { class: "divide-y text-sm dark:divide-gray-900" }
const _hoisted_3 = { class: "rounded-t bg-gray-50 px-3 pt-3 pb-1 dark:bg-gray-800" }
const _hoisted_4 = { class: "text-secondary" }
const _hoisted_5 = { class: "p-3" }
const _hoisted_6 = { class: "mb-1 flex items-center whitespace-nowrap" }
const _hoisted_7 = { class: "text-secondary ml-1 text-xs" }

import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { isVeBalPool, totalAprLabel } from '@/composables/usePool';
import { APR_THRESHOLD } from '@/constants/pools';
import { bnum } from '@/lib/utils';
import { Pool, PoolAPRs } from '@/services/pool/types';
import { hasStakingRewards } from '@/services/staking/utils';

import StakingBreakdown from './components/StakingBreakdown.vue';
import VeBalBreakdown from './components/VeBalBreakdown.vue';
import YieldBreakdown from './components/YieldBreakdown.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  poolApr?: PoolAPRs;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    poolApr: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  poolApr?: PoolAPRs;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */
const apr = computed(() => props.pool?.apr || props.poolApr);
const validAPR = computed(
  () => Number(apr.value?.total?.unstaked || '0') * 100 <= APR_THRESHOLD
);

const hasYieldAPR = computed(() => bnum(apr.value?.yield.total || '0').gt(0));

const hasVebalAPR = computed((): boolean => isVeBalPool(props.pool.id));

const totalLabel = computed((): string =>
  apr.value ? totalAprLabel(apr.value, props.pool.boost) : '0'
);

return (_ctx: any,_cache: any) => {
  const _component_StarsIcon = _resolveComponent("StarsIcon")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!

  return (_unref(validAPR))
    ? (_openBlock(), _createBlock(_component_BalTooltip, {
        key: 0,
        width: "auto",
        noPad: ""
      }, {
        activator: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_unref(hasYieldAPR) || _unref(hasStakingRewards)(_unref(apr)) || _unref(hasVebalAPR))
              ? (_openBlock(), _createBlock(_component_StarsIcon, _mergeProps({
                  key: 0,
                  gradFrom: _unref(hasVebalAPR) ? 'purple' : 'yellow',
                  class: "-mr-1 h-4"
                }, _ctx.$attrs), null, 16, ["gradFrom"]))
              : (_openBlock(), _createBlock(_component_BalIcon, _mergeProps({
                  key: 1,
                  name: "info",
                  size: "sm",
                  class: "text-gray-400 dark:text-gray-500"
                }, _ctx.$attrs), null, 16))
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('totalAPR')), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(totalLabel)), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createTextVNode(_toDisplayString(_unref(fNum2)(_unref(apr)?.swap || '0', _unref(FNumFormats).percent)) + " ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('swapFeeAPR')), 1)
              ]),
              (_unref(hasVebalAPR))
                ? (_openBlock(), _createBlock(VeBalBreakdown, {
                    key: 0,
                    apr: _unref(apr)?.veBal || '0'
                  }, null, 8, ["apr"]))
                : _createCommentVNode("", true),
              (_unref(hasYieldAPR))
                ? (_openBlock(), _createBlock(YieldBreakdown, {
                    key: 1,
                    yieldAPR: _unref(apr)?.yield,
                    poolTokens: __props.pool.tokensList,
                    poolType: __props.pool.poolType
                  }, null, 8, ["yieldAPR", "poolTokens", "poolType"]))
                : _createCommentVNode("", true),
              _createVNode(StakingBreakdown, {
                pool: __props.pool,
                poolApr: _unref(apr)
              }, null, 8, ["pool", "poolApr"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})