import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-secondarytext-xs ml-1" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-secondary ml-1 text-xs" }
const _hoisted_6 = { class: "text-secondary ml-1 text-xs capitalize" }
const _hoisted_7 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_8 = { class: "text-secondary ml-1 text-xs" }

import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import { Pool, PoolAPRs } from '@/services/pool/types';
import { hasBalEmissions } from '@/services/staking/utils';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  poolApr?: PoolAPRs;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    poolApr: { type: null, required: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  poolApr?: PoolAPRs;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();

/**
 * COMPUTED
 */

const apr = computed(() => props.pool?.apr || props.poolApr);

const boost = computed((): string => props.pool?.boost || '');
const hasBoost = computed((): boolean => !!boost.value);
const stakingAPR = computed((): PoolAPRs['staking'] => apr.value?.staking);
const minBalAPR = computed((): string => stakingAPR.value?.bal.min || '0');
const maxBalAPR = computed((): string => stakingAPR.value?.bal.max || '0');
const rewardTokensAPR = computed(
  (): string => stakingAPR.value?.rewards || '0'
);
const hasRewardTokens = computed((): boolean =>
  bnum(rewardTokensAPR.value).gt(0)
);

/**
 * @summary The total APR if we have the user's boost.
 */
const boostedTotalAPR = computed((): string => {
  if (apr.value && hasBalEmissions(apr.value)) {
    const boostedStakingAPR = bnum(minBalAPR.value)
      .times(boost.value)
      .plus(rewardTokensAPR.value)
      .toString();

    return fNum2(boostedStakingAPR, FNumFormats.percent);
  }

  return fNum2(rewardTokensAPR.value, FNumFormats.percent);
});

/**
 * @summary The total APR if we have don't have the user's boost.
 */
const unboostedTotalAPR = computed((): string =>
  fNum2(
    bnum(minBalAPR.value).plus(rewardTokensAPR.value).toString(),
    FNumFormats.percent
  )
);

const breakdownItems = computed((): Array<any> => {
  const items: Array<any> = [];

  items.push(['Min BAL', minBalAPR.value], ['Max BAL', maxBalAPR.value]);

  if (hasRewardTokens.value) items.push(['Rewards', rewardTokensAPR.value]);

  return items;
});

return (_ctx: any,_cache: any) => {
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(hasBoost))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_unref(boostedTotalAPR)) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('staking.stakingApr')), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_unref(hasBalEmissions)(_unref(apr)))
            ? (_openBlock(), _createBlock(_component_BalBreakdown, {
                key: 0,
                items: _unref(breakdownItems)
              }, {
                item: _withCtx(({ item: [label, amount] }) => [
                  _createTextVNode(_toDisplayString(_unref(fNum2)(amount, _unref(FNumFormats).percent)) + " ", 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(label) + " " + _toDisplayString(_ctx.$t('apr')), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_unref(unboostedTotalAPR)) + " ", 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('staking.minimumStakingApr')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["items"]))
            : (_unref(hasRewardTokens))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createTextVNode(_toDisplayString(_unref(fNum2)(_unref(rewardTokensAPR), _unref(FNumFormats).percent)) + " ", 1),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('staking.stakingApr')), 1)
                ]))
              : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})