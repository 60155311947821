import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "text-secondary text-xs leading-none" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "mb-6" }
const _hoisted_5 = { class: "text-secondary" }
const _hoisted_6 = { class: "text-lg font-semibold" }
const _hoisted_7 = { class: "my-4 flex justify-center dark:text-gray-50" }

import { onBeforeMount, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import TradeSettingsPopover, {
  TradeSettingsContext,
} from '@/components/popovers/TradeSettingsPopover.vue';
import { MIN_FIAT_VALUE_POOL_MIGRATION } from '@/constants/pools';
import { bnum } from '@/lib/utils';
import { configService } from '@/services/config/config.service';
import { Pool } from '@/services/pool/types';
import { TokenInfo } from '@/types/TokenList';

import useMigrateMath from '../../composables/useMigrateMath';
import { PoolMigrationInfo } from '../../types';
import MigratePreviewModal from '../MigratePreviewModal/MigratePreviewModal.vue';
import PoolInfoBreakdown from './components/PoolInfoBreakdown.vue';

type Props = {
  poolMigrationInfo: PoolMigrationInfo;
  fromPool: Pool;
  toPool: Pool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    poolMigrationInfo: { type: null, required: true },
    fromPool: { type: null, required: true },
    toPool: { type: null, required: true },
    fromPoolTokenInfo: { type: null, required: true },
    toPoolTokenInfo: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  poolMigrationInfo: PoolMigrationInfo;
  fromPool: Pool;
  toPool: Pool;
  fromPoolTokenInfo: TokenInfo;
  toPoolTokenInfo: TokenInfo;
}) {

const props = __props


/**
 * STATE
 */
const showPreviewModal = ref(false);

/**
 * COMPOSABLES
 */
const { t } = useI18n();

const { fromPool, toPool } = toRefs(props);

const router = useRouter();

const migrateMath = useMigrateMath(fromPool, toPool);
const { hasBpt, fiatTotalLabel, fiatTotal } = migrateMath;

/**
 * CALLBACKS
 */
onBeforeMount(() => {
  migrateMath.getBatchSwap();

  if (bnum(fiatTotal.value).lt(MIN_FIAT_VALUE_POOL_MIGRATION)) {
    router.push({ name: 'pool', params: { id: fromPool.value.id } });
  }
});

return (_ctx: any,_cache: any) => {
  const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BalCard, {
      shadow: "xl",
      exposeOverflow: "",
      noBorder: ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(configService).network.chainName), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h4", null, _toDisplayString(_unref(t)(`migratePool.${__props.poolMigrationInfo.type}.migrateToPool.title`)), 1),
            _createVNode(TradeSettingsPopover, {
              context: _unref(TradeSettingsContext).invest
            }, null, 8, ["context"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('yourBalanceInPool')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(hasBpt) ? _unref(fiatTotalLabel) : '-'), 1)
        ]),
        _createVNode(PoolInfoBreakdown, {
          pool: _unref(fromPool),
          poolTokenInfo: __props.fromPoolTokenInfo
        }, null, 8, ["pool", "poolTokenInfo"]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ArrowDownIcon, { class: "dark:text-secondary h-5 w-5" })
        ]),
        _createVNode(PoolInfoBreakdown, {
          pool: _unref(toPool),
          poolTokenInfo: __props.toPoolTokenInfo
        }, null, 8, ["pool", "poolTokenInfo"]),
        _createVNode(_component_BalBtn, {
          color: "gradient",
          class: "mt-6",
          block: "",
          disabled: !_unref(hasBpt),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showPreviewModal.value = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('previewMigrate')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
      (showPreviewModal.value)
        ? (_openBlock(), _createBlock(MigratePreviewModal, {
            key: 0,
            fromPool: _unref(fromPool),
            toPool: _unref(toPool),
            fromPoolTokenInfo: __props.fromPoolTokenInfo,
            toPoolTokenInfo: __props.toPoolTokenInfo,
            poolMigrationInfo: __props.poolMigrationInfo,
            math: _unref(migrateMath),
            onClose: _cache[1] || (_cache[1] = ($event: any) => (showPreviewModal.value = false))
          }, null, 8, ["fromPool", "toPool", "fromPoolTokenInfo", "toPoolTokenInfo", "poolMigrationInfo", "math"]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
}

})